<template>
  <div class="member-container">
    <div class="scroll-Y member-wrapper">
      <div class=" member-box">
        <div class="member-title">账号立即升级</div>
        <div class="member-desc">
          好的服务建立在团队有持续性收入来维持开发和运维，我们提供了不同收费形式，给用户开放更多权限。
        </div>
        <div class="flex-center member-type">
          <div class="flex member-type-box">
            <div
              class="flex-center member-type-item"
              v-for="(item, index) in typeList"
              :key="index"
              :class="{ 'member-type-active': typeIndex == index }"
              @click="changeType(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="member-tyb"
            @click="showDialog = true"
            v-if="!packData || (packData && !packData.trialPackUsed)"
          >
            试试体验版
          </div>
        </div>
        <div class="flex member-play">
          <div
            class="member-play-item"
            v-for="(item, idx) in playList"
            :key="idx"
            :class="{ 'paly-svip': item.packageType.indexOf('SVIP') > -1 }"
          >
            <img
              src="~images/home/icon_tj.png"
              alt=""
              v-if="item.packageType.indexOf('SVIP') > -1"
              class="svip-img"
            />
            <div class="play-item-top">
              <div class="flex-center play-title">
                <div class="member-play-name">
                  {{ item.packageName }}
                </div>
              </div>
              <div
                class="flex-center play-desc"
                v-if="item.packageType.indexOf('FREE') > -1"
              >
                {{ item.packageDesc }}
              </div>
              <div
                class="flex-center play-desc play-money"
                v-else
              >
                ¥
                <span class="money">{{ item.playList[typeIndex].value }}</span>
                <span style="margin:0 4px;">/</span>
                {{ typeList[typeIndex].unit }}
              </div>
              <div class="flex-center paly-btn-box">
                <el-button
                  type="danger"
                  v-if="
                    item.packageType.indexOf('VIP') > -1 &&
                      getRoleText(item.packageType)
                  "
                  @click="goPlay(item, getRoleText(item.packageType))"
                >
                  {{ getRoleText(item.packageType) }}
                </el-button>
              </div>
            </div>
            <div class="play-list">
              <div class="flex play-list-item">
                <div class="flex-center play-name">
                  功能
                </div>
                <div class="flex-center play-icon-box">
                  权限
                </div>
              </div>
              <div
                class="flex play-list-item"
                v-for="(it, i) in item.typeList"
                :key="i"
              >
                <div class="flex-center play-name">
                  {{ it.name }}
                  <div
                    style="text-align: center;margin-top: 4px;"
                    v-if="it.sname"
                  >
                    {{ it.sname }}
                  </div>
                </div>
                <div class="flex-center play-icon-box">
                  <template v-if="getNames(it.name, it.value)">
                    <img
                      :src="getPackFalse(it.value) ? readImg : errorImg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    {{ it.value == -100 ? "不限制" : it.value }}
                    <template v-if="it.unit && it.value > -1">
                      {{ it.unit }}
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :append-to-body="true"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      width="370px"
      class="ty-dialog"
      :show-close="false"
      v-dialogDrag
    >
      <div
        class="scroll-Y form-wrapper"
        style="max-height:400px;"
      >
        <div class="flex member-play">
          <div class="member-play-item">
            <div class="play-item-top">
              <div class="flex-center play-title">
                <div class="title-1 member-play-name">
                  {{ tybData.packageName }}
                </div>
              </div>
              <div
                class="flex-center play-desc"
                v-if="tybData.packageType.indexOf('试用') > -1"
              >
                {{ tybData.packageDesc }}
                <template> {{ tybData.freeDay }} 天 </template>
              </div>
            </div>
            <div
              class="play-list"
              style="margin-bottom:0;"
            >
              <div class="flex play-list-item">
                <div class="flex-center play-name">
                  功能
                </div>
                <div class="flex-center play-icon-box">
                  权限
                </div>
              </div>
              <div
                class="flex play-list-item"
                v-for="(it, i) in tybData.typeList"
                :key="i"
              >
                <div class="flex-center play-name">
                  {{ it.name }}
                  <div
                    style="text-align: center;margin-top: 4px;"
                    v-if="it.sname"
                  >
                    {{ it.sname }}
                  </div>
                </div>
                <div class="flex-center play-icon-box">
                  <template v-if="getNames(it.name, it.value)">
                    <img
                      :src="getPackFalse(it.value) ? readImg : errorImg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    {{ it.value == -100 ? "不限制" : it.value }}
                    <template v-if="it.unit && it.value > -1">
                      {{ it.unit }}
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer ty-footer"
      >
        <el-button
          @click="cancelDialog"
          size="nomal"
          native-type="button"
        >取消体验</el-button>
        <el-button
          @click="doSubmit"
          type="primary"
          size="nomal"
        >确定体验</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showRenew"
      :modal="true"
      :modal-append-to-body="false"
      :title="playtitle"
      width="620px"
      style="text-align:left"
      v-dialogDrag
    >
      <div
        class="form-wrapper"
        style="margin:0 14px;"
        v-if="renewData.typeList.length > 0"
      >
        <el-form
          ref="form"
          :model="renewData"
          size="small"
          label-width="auto"
        >
          <el-form-item label="付费选择">
            <el-radio-group v-model="renewData.type">
              <el-radio :label="0">按月支付</el-radio>
              <el-radio :label="1">按季度支付</el-radio>
              <el-radio :label="2">按半年支付</el-radio>
              <el-radio :label="3">按年度支付</el-radio>
            </el-radio-group>
            <div
              class="flex-center type-desc"
              v-if="renewData.type == 3 && !isUpgradation"
            >
              <img
                src="~images/home/icon_money.png"
                alt=""
              />
              年付立省{{
                renewData.typeList[renewData.type].deduct
                  ? renewData.typeList[renewData.type].deduct.toFixed(2)
                  : 0
              }}元
            </div>
          </el-form-item>
          <el-form-item label="到期时间">
            {{ renewData.typeList[renewData.type].payEndTime }}
            <span
              class="renew-time"
              v-if="renewData.typeList[renewData.type].originalEndTime"
            >原到期时间：{{
                renewData.typeList[renewData.type].originalEndTime
              }}</span>
          </el-form-item>
          <el-form-item label="支付方式">
            <div class="flex zf-box">
              <div
                class="flex zf-item"
                v-for="(item, index) in zfList"
                :key="index"
                :class="{ 'zf-item-active': renewData.playWay == item.type }"
                @click="changeWay(item.type)"
              >
                <img
                  :src="item.url"
                  alt=""
                />
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="支付金额"
            v-if="renewData.typeList.length > 0"
          >
            {{ renewData.typeList[renewData.type].money }} 元
            <span
              style="color:red;"
              v-if="isUpgradation"
            >（原付费版本可抵扣{{
                renewData.typeList[renewData.type].remaining
                  ? renewData.typeList[renewData.type].remaining.toFixed(2)
                  : 0
              }}元）</span>
          </el-form-item>
        </el-form>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="cancelRenew"
          size="nomal"
          native-type="button"
        >取消</el-button>
        <el-button
          @click="sureRenew"
          type="primary"
          size="nomal"
          :loading="playLoading"
        >前往支付</el-button>
      </div>
      <el-dialog
        width="30%"
        :close-on-click-modal="false"
        :title="wayTitle"
        :visible.sync="showPlay"
        append-to-body
        v-dialogDrag
      >
        <!--  :logoSrc="logo" -->
        <div class="flex-center play-qr-box">
          <div style="position:relative;">
            <div
              class="vue-qr-sxbox animated bounceIn"
              v-if="loading"
            >
              <div class="vue-qr-title">
                <i class="el-icon-loading"></i>{{ statusTxt }}...
              </div>
            </div>
            <vue-qr
              :text="imgUrl"
              :size="250"
              :logo-scale="0.2"
              :margin="10"
              :dot-scale="1"
              color-dark="#000"
              color-light="#fff"
              :logoSrc="logo"
            >
            </vue-qr>
          </div>
        </div>
      </el-dialog>
    </el-dialog>
    <div
      class="ali_form"
      v-show="false"
      v-html="applyForm"
    ></div>
  </div>
</template>

<script>
import { get, post } from "@/api/index";
import VueQr from "vue-qr";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";
export default {
  name: "",
  components: { VueQr },
  data () {
    return {
      qxList: [
        {
          name: "在线查看图纸",
          type: "feaOnlineView",
          showType: "feaOnlineViewVisible",
          value: false,
          isShow: false
        },
        {
          name: "用户dwg图纸下载",
          type: "feaDwgDownload",
          showType: "feaDwgDownloadVisible",
          value: false,
          isShow: false
        },
        {
          name: "用户多种格式图纸下载",
          sname: "(dwg、pdf)",
          type: "feaDwgPdfDownload",
          showType: "feaDwgPdfDownloadVisible",
          value: false,
          isShow: false
        },
        {
          name: "在线CAD编辑",
          type: "feaCadOnlineEdit",
          showType: "feaCadOnlineEditVisible",
          value: false,
          isShow: false
        },
        {
          name: "图纸导出为单张PDF",
          type: "feaExportSinglePdf",
          showType: "feaExportSinglePdfVisible",
          value: false,
          isShow: false
        },
        {
          name: "自动绘制平面图",
          sname: "(不含绘制大样图)",
          type: "feaAutoDraw",
          showType: "feaAutoDrawVisible",
          value: false,
          isShow: false
        },
        {
          name: "规范查询",
          type: "feaStandardQuery",
          showType: "feaStandardQueryVisible",
          value: false,
          isShow: false
        },
        {
          name: "产品查询",
          type: "feaProductQuery",
          showType: "feaProductQueryVisible",
          value: false,
          isShow: false
        },
        {
          name: "图纸批量导出PDF",
          type: "feaExportMultiPdf",
          showType: "feaExportMultiPdfVisible",
          value: false,
          isShow: false
        },
        // {
        //   name: "自动绘制大样图",
        //   type: "feaAutoDrawBig",
        //   value: false
        // },
        // {
        //   name: "方案优化",
        //   type: "feaSolutionOptimization",
        //   value: false
        // },
        {
          name: "问题答疑",
          type: "feaQuestionAnswer",
          showType: "feaQuestionAnswerVisible",
          value: false,
          isShow: false
        },
        {
          name: "专家解惑",
          type: "feaExpertAnswer",
          showType: "feaExpertAnswerVisible",
          value: false,
          isShow: false
        },
        {
          name: "创建工程数量",
          type: "feaCreateProjectCount",
          showType: "feaCreateProjectCountVisible",
          value: false,
          isShow: false
        },
        {
          name: "创建工程建筑面积",
          type: "feaCreateProjectArea",
          showType: "feaCreateProjectAreaVisible",
          value: false,
          isShow: false,
          unit: '万平方米'
        },
        {
          name: "个人存储空间",
          type: "feaUserSpace",
          showType: "feaUserSpaceVisible",
          value: false,
          isShow: false,
          unit: "M"
        }
      ],
      typeIndex: 0,
      typeList: [
        {
          name: "月付",
          unit: "月",
          value: 1
        },
        {
          name: "季度付",
          unit: "季度",
          value: 2
        },
        {
          name: "半年付",
          unit: "半年",
          value: 2
        },
        {
          name: "年付",
          unit: "年",
          value: 4
        }
      ],
      playList: [
        {
          id: "",
          packageName: "免费用户",
          packageDesc: "永久免费使用",
          packageType: "FREE",
          typeList: [
            {
              name: "在线查看图纸",
              type: "feaOnlineView",
              showType: "feaOnlineViewVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户dwg图纸下载",
              type: "feaDwgDownload",
              showType: "feaDwgDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户多种格式图纸下载",
              sname: "(dwg、pdf)",
              type: "feaDwgPdfDownload",
              showType: "feaDwgPdfDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "在线CAD编辑",
              type: "feaCadOnlineEdit",
              showType: "feaCadOnlineEditVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸导出为单张PDF",
              type: "feaExportSinglePdf",
              showType: "feaExportSinglePdfVisible",
              value: false,
              isShow: false
            },
            {
              name: "自动绘制平面图",
              sname: "(不含绘制大样图)",
              type: "feaAutoDraw",
              showType: "feaAutoDrawVisible",
              value: false,
              isShow: false
            },
            {
              name: "规范查询",
              type: "feaStandardQuery",
              showType: "feaStandardQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "产品查询",
              type: "feaProductQuery",
              showType: "feaProductQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸批量导出PDF",
              type: "feaExportMultiPdf",
              showType: "feaExportMultiPdfVisible",
              value: false,
              isShow: false
            },
            // {
            //   name: "自动绘制大样图",
            //   type: "feaAutoDrawBig",
            //   value: false
            // },
            // {
            //   name: "方案优化",
            //   type: "feaSolutionOptimization",
            //   value: false
            // },
            {
              name: "问题答疑",
              type: "feaQuestionAnswer",
              showType: "feaQuestionAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "专家解惑",
              type: "feaExpertAnswer",
              showType: "feaExpertAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程数量",
              type: "feaCreateProjectCount",
              showType: "feaCreateProjectCountVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程建筑面积",
              type: "feaCreateProjectArea",
              showType: "feaCreateProjectAreaVisible",
              value: false,
              isShow: false,
              unit: '万平方米'
            },
            {
              name: "个人存储空间",
              type: "feaUserSpace",
              showType: "feaUserSpaceVisible",
              value: false,
              isShow: false,
              unit: "M"
            }
          ]
        },
        {
          id: "",
          packageName: "VIP用户",
          class: "vip",
          packageType: "VIP",
          playList: [
            {
              name: "月",
              type: "billingMonth",
              value: 0
            },
            {
              name: "季",
              type: "billingSeason",
              value: 0
            },
            {
              name: "半年",
              type: "billingHalfYear",
              value: 0
            },
            {
              name: "年",
              type: "billingYear",
              value: 0
            }
          ],
          typeList: [
            {
              name: "在线查看图纸",
              type: "feaOnlineView",
              showType: "feaOnlineViewVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户dwg图纸下载",
              type: "feaDwgDownload",
              showType: "feaDwgDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户多种格式图纸下载",
              sname: "(dwg、pdf)",
              type: "feaDwgPdfDownload",
              showType: "feaDwgPdfDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "在线CAD编辑",
              type: "feaCadOnlineEdit",
              showType: "feaCadOnlineEditVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸导出为单张PDF",
              type: "feaExportSinglePdf",
              showType: "feaExportSinglePdfVisible",
              value: false,
              isShow: false
            },
            {
              name: "自动绘制平面图",
              sname: "(不含绘制大样图)",
              type: "feaAutoDraw",
              showType: "feaAutoDrawVisible",
              value: false,
              isShow: false
            },
            {
              name: "规范查询",
              type: "feaStandardQuery",
              showType: "feaStandardQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "产品查询",
              type: "feaProductQuery",
              showType: "feaProductQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸批量导出PDF",
              type: "feaExportMultiPdf",
              showType: "feaExportMultiPdfVisible",
              value: false,
              isShow: false
            },
            // {
            //   name: "自动绘制大样图",
            //   type: "feaAutoDrawBig",
            //   value: false
            // },
            // {
            //   name: "方案优化",
            //   type: "feaSolutionOptimization",
            //   value: false
            // },
            {
              name: "问题答疑",
              type: "feaQuestionAnswer",
              showType: "feaQuestionAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "专家解惑",
              type: "feaExpertAnswer",
              showType: "feaExpertAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程数量",
              type: "feaCreateProjectCount",
              showType: "feaCreateProjectCountVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程建筑面积",
              type: "feaCreateProjectArea",
              showType: "feaCreateProjectAreaVisible",
              value: false,
              isShow: false,
              unit: '万平方米'
            },
            {
              name: "个人存储空间",
              type: "feaUserSpace",
              showType: "feaUserSpaceVisible",
              value: false,
              isShow: false,
              unit: "M"
            }
          ]
        },
        {
          id: "",
          packageName: "SVIP用户",
          packageType: "SVIP",
          class: "svip",
          playList: [
            {
              name: "月",
              type: "billingMonth",
              value: 0
            },
            {
              name: "季",
              type: "billingSeason",
              value: 0
            },
            {
              name: "半年",
              type: "billingHalfYear",
              value: 0
            },
            {
              name: "年",
              type: "billingYear",
              value: 0
            }
          ],
          typeList: [
            {
              name: "在线查看图纸",
              type: "feaOnlineView",
              showType: "feaOnlineViewVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户dwg图纸下载",
              type: "feaDwgDownload",
              showType: "feaDwgDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "用户多种格式图纸下载",
              sname: "(dwg、pdf)",
              type: "feaDwgPdfDownload",
              showType: "feaDwgPdfDownloadVisible",
              value: false,
              isShow: false
            },
            {
              name: "在线CAD编辑",
              type: "feaCadOnlineEdit",
              showType: "feaCadOnlineEditVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸导出为单张PDF",
              type: "feaExportSinglePdf",
              showType: "feaExportSinglePdfVisible",
              value: false,
              isShow: false
            },
            {
              name: "自动绘制平面图",
              sname: "(不含绘制大样图)",
              type: "feaAutoDraw",
              showType: "feaAutoDrawVisible",
              value: false,
              isShow: false
            },
            {
              name: "规范查询",
              type: "feaStandardQuery",
              showType: "feaStandardQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "产品查询",
              type: "feaProductQuery",
              showType: "feaProductQueryVisible",
              value: false,
              isShow: false
            },
            {
              name: "图纸批量导出PDF",
              type: "feaExportMultiPdf",
              showType: "feaExportMultiPdfVisible",
              value: false,
              isShow: false
            },
            // {
            //   name: "自动绘制大样图",
            //   type: "feaAutoDrawBig",
            //   value: false
            // },
            // {
            //   name: "方案优化",
            //   type: "feaSolutionOptimization",
            //   value: false
            // },
            {
              name: "问题答疑",
              type: "feaQuestionAnswer",
              showType: "feaQuestionAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "专家解惑",
              type: "feaExpertAnswer",
              showType: "feaExpertAnswerVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程数量",
              type: "feaCreateProjectCount",
              showType: "feaCreateProjectCountVisible",
              value: false,
              isShow: false
            },
            {
              name: "创建工程建筑面积",
              type: "feaCreateProjectArea",
              showType: "feaCreateProjectAreaVisible",
              value: false,
              isShow: false,
              unit: '万平方米'
            },
            {
              name: "个人存储空间",
              type: "feaUserSpace",
              showType: "feaUserSpaceVisible",
              value: false,
              isShow: false,
              unit: "M"
            }
          ]
        }
      ],
      tybData: {
        id: "",
        packageName: "试用版本",
        class: "trial",
        packageType: "TRIAL",
        packageDesc: "免费体验",
        freeDay: 0,
        typeList: [
          {
            name: "在线查看图纸",
            type: "feaOnlineView",
            showType: "feaOnlineViewVisible",
            value: false,
            isShow: false
          },
          {
            name: "用户dwg图纸下载",
            type: "feaDwgDownload",
            showType: "feaDwgDownloadVisible",
            value: false,
            isShow: false
          },
          {
            name: "用户多种格式图纸下载",
            sname: "(dwg、pdf)",
            type: "feaDwgPdfDownload",
            showType: "feaDwgPdfDownloadVisible",
            value: false,
            isShow: false
          },
          {
            name: "在线CAD编辑",
            type: "feaCadOnlineEdit",
            showType: "feaCadOnlineEditVisible",
            value: false,
            isShow: false
          },
          {
            name: "图纸导出为单张PDF",
            type: "feaExportSinglePdf",
            showType: "feaExportSinglePdfVisible",
            value: false,
            isShow: false
          },
          {
            name: "自动绘制平面图",
            sname: "(不含绘制大样图)",
            type: "feaAutoDraw",
            showType: "feaAutoDrawVisible",
            value: false,
            isShow: false
          },
          {
            name: "规范查询",
            type: "feaStandardQuery",
            showType: "feaStandardQueryVisible",
            value: false,
            isShow: false
          },
          {
            name: "产品查询",
            type: "feaProductQuery",
            showType: "feaProductQueryVisible",
            value: false,
            isShow: false
          },
          {
            name: "图纸批量导出PDF",
            type: "feaExportMultiPdf",
            showType: "feaExportMultiPdfVisible",
            value: false,
            isShow: false
          },
          // {
          //   name: "自动绘制大样图",
          //   type: "feaAutoDrawBig",
          //   value: false
          // },
          // {
          //   name: "方案优化",
          //   type: "feaSolutionOptimization",
          //   value: false
          // },
          {
            name: "问题答疑",
            type: "feaQuestionAnswer",
            showType: "feaQuestionAnswerVisible",
            value: false,
            isShow: false
          },
          {
            name: "专家解惑",
            type: "feaExpertAnswer",
            showType: "feaExpertAnswerVisible",
            value: false,
            isShow: false
          },
          {
            name: "创建工程数量",
            type: "feaCreateProjectCount",
            showType: "feaCreateProjectCountVisible",
            value: false,
            isShow: false
          },
          {
            name: "创建工程建筑面积",
            type: "feaCreateProjectArea",
            showType: "feaCreateProjectAreaVisible",
            value: false,
            isShow: false,
            unit: '万平方米'
          },
          {
            name: "个人存储空间",
            type: "feaUserSpace",
            showType: "feaUserSpaceVisible",
            value: false,
            isShow: false,
            unit: "M"
          }
        ]
      },
      showDialog: false,
      readImg: require("images/home/icon_sure.png"),
      errorImg: require("images/home/icon_error.png"),
      showRenew: false,
      renewData: {
        id: "",
        type: 1,
        typeList: [],
        dqDate: new Date(),
        date: this.$funGetDate(365),
        playWay: "WECHAT",
        money: 1200
      },
      zfList: [
        {
          name: "微信支付",
          type: "WECHAT",
          url: require("images/home/zf_wx.png")
        },
        {
          name: "支付宝支付",
          type: "ALIPAY",
          url: require("images/home/zf_zfb.png")
        }
      ],
      playtitle: "",
      roleText: "开通",
      wayTitle: "",
      showPlay: false,
      isUpgradation: false,
      imgUrl: "",
      logo_wx: require("images/home/zf_wx.png"),
      logo_zfb: require("images/home/zf_zfb.png"),
      logo: "",
      orderUid: "",
      timer: null,
      loading: false,
      statusTxt: "",
      applyForm: null,
      playLoading: false
    };
  },
  created () { },
  computed: {
    ...mapGetters(["packData"])
  },
  mounted () {
    this.initData();
  },
  activated () {
    this.initData();
  },
  methods: {
    getRoleText (type) {
      let text = "购买";
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType
      ) {
        if (
          this.packData.packageSimple.packageType == "FREE" ||
          this.packData.packageSimple.packageType == "TRIAL"
        ) {
          text = "购买";
        } else if (this.packData.packageSimple.packageType == "SVIP") {
          if (this.packData.packageSimple.packageType == type) text = "续费";
          else text = "";
        } else if (this.packData.packageSimple.packageType == "VIP") {
          if (this.packData.packageSimple.packageType == type) text = "续费";
          else text = "立即升级";
        }
      }

      return text;
    },
    getPackFalse (val) {
      if (val && val != -200 && val != "false") return true;
      else return false;
    },
    getNames (name, value) {
      if (
        name == "个人存储空间" ||
        name == "创建工程数量" ||
        name == "创建工程建筑面积" ||
        name == "专家解惑"
      ) {
        if (value && value != -200) return false;
        return true;
      } else return true;
    },
    initData () {
      get(this.$setApi("/cus/package-simple/list")).then(res => {
        if (res.data && res.data.length > 0) {
          let qxLists = JSON.parse(JSON.stringify(this.qxList));
          res.data.map(item => {
            if (item.packageType.indexOf("FREE") > -1) {
              this.playList[0].id = item.id;
              this.playList[0].packageName = item.packageName;
              this.playList[0].packageDesc = item.packageDesc;
              this.playList[0].packageType = item.packageType;
              this.playList[0].typeList = [];
              qxLists.map(qx => {
                if (item[qx.showType]) {
                  let datas = JSON.parse(JSON.stringify(qx));
                  datas.value = item[qx.type];
                  datas.isShow = item[qx.showType];
                  this.playList[0].typeList.push(datas);
                }
              });
            } else if (item.packageType == "VIP") {
              this.playList[1].id = item.id;
              this.playList[1].packageName = item.packageName;
              this.playList[1].packageDesc = item.packageDesc;
              this.playList[1].packageType = item.packageType;
              this.playList[1].typeList = [];
              qxLists.map(qx => {
                if (item[qx.showType]) {
                  let datas = JSON.parse(JSON.stringify(qx));
                  datas.value = item[qx.type];
                  datas.isShow = item[qx.showType];
                  this.playList[1].typeList.push(datas);
                }
              });
              this.playList[1].playList.map(it => {
                it.value = item[it.type];
                return it;
              });
            } else if (item.packageType == "SVIP") {
              this.playList[2].id = item.id;
              this.playList[2].packageName = item.packageName;
              this.playList[2].packageType = item.packageType;
              this.playList[2].typeList = [];
              qxLists.map(qx => {
                if (item[qx.showType]) {
                  let datas = JSON.parse(JSON.stringify(qx));
                  datas.value = item[qx.type];
                  datas.isShow = item[qx.showType];
                  this.playList[2].typeList.push(datas);
                }
              });
              this.playList[2].playList.map(el => {
                el.value = String(item[el.type]);
                return el;
              });
            } else if (item.packageType.indexOf("TRIAL") > -1) {
              this.tybData.id = item.id;
              this.tybData.packageName = item.packageName;
              this.tybData.packageDesc = item.packageDesc;
              this.tybData.freeDay = item.freeDay;
              this.tybData.packageType = item.packageType;
              this.tybData.typeList = [];
              qxLists.map(qx => {
                if (item[qx.showType]) {
                  let datas = JSON.parse(JSON.stringify(qx));
                  datas.value = item[qx.type];
                  datas.isShow = item[qx.showType];
                  this.tybData.typeList.push(datas);
                }
              });
            }
          });
          console.log("getData", res, this.playList, this.tybData);
        }
      });
    },
    // 选择会员类型
    changeType (index) {
      if (this.typeIndex == index) return;
      this.typeIndex = index;
    },
    doSubmit () {
      post(this.$setApi("/cus/muser-package/trial")).then(res => {
        if (res.code == 200 && res.data) {
          this.showDialog = false;
          this.$store.dispatch("user/getUserVip");
          this.$store.dispatch("user/getUserInfo");
        }
      });
    },
    cancelDialog () {
      this.showDialog = false;
    },
    goPlay (item, text) {
      this.loading = false;
      this.orderUid = "";
      this.isUpgradation = text.indexOf("升级") > -1;
      this.playtitle = this.isUpgradation
        ? "立即升级"
        : "续费" + item.packageName;
      console.log("goPlay", item, text, this.vipTxt, this.isUpgradation);
      get(this.$setApi("/cus/muser-package/offer/") + item.id).then(res => {
        if (res && res.data) {
          this.renewData.id = item.id;
          this.renewData.type = this.typeIndex;
          this.renewData.typeList = [];

          let datas = res.data;
          let times = res.data.time;
          this.renewData.typeList.push({
            money: datas.billingMonth,
            originalEndTime: this.$formatDate(
              times.MONTH && times.MONTH.originalEndTime,
              "yyyy年MM月dd日"
            ),
            payEndTime: this.$formatDate(
              times.MONTH && times.MONTH.payEndTime,
              "yyyy年MM月dd日"
            ),
            deduct: "",
            remaining: datas.remaining
          });
          this.renewData.typeList.push({
            money: datas.billingSeason,
            originalEndTime: this.$formatDate(
              times.SEASON && times.SEASON.originalEndTime,
              "yyyy年MM月dd日"
            ),
            payEndTime: this.$formatDate(
              times.SEASON && times.SEASON.payEndTime,
              "yyyy年MM月dd日"
            ),
            deduct: "",
            remaining: datas.remaining
          });
          this.renewData.typeList.push({
            money: datas.billingHalfYear,
            originalEndTime: this.$formatDate(
              times.HALF_YEAR && times.HALF_YEAR.originalEndTime,
              "yyyy年MM月dd日"
            ),
            payEndTime: this.$formatDate(
              times.HALF_YEAR && times.HALF_YEAR.payEndTime,
              "yyyy年MM月dd日"
            ),
            deduct: "",
            remaining: datas.remaining
          });
          this.renewData.typeList.push({
            money: datas.billingYear,
            originalEndTime: this.$formatDate(
              times.YEAR && times.YEAR.originalEndTime,
              "yyyy年MM月dd日"
            ),
            payEndTime: this.$formatDate(
              times.YEAR && times.YEAR.payEndTime,
              "yyyy年MM月dd日"
            ),
            deduct: datas.deduct,
            remaining: datas.remaining
          });
          this.showRenew = true;
        }
        console.log("计费", this.renewData.typeList);
      });
    },
    cancelRenew () {
      this.showRenew = false;
      this.playtitle = "";
    },
    sureRenew () {
      // this.showRenew = false;
      // this.playtitle = "";
      let data = {
        billingCycle: this.renewData.type,
        packageId: this.renewData.id,
        payType: this.renewData.playWay
      };
      this.logo = this.logo_wx;
      this.wayTitle = "微信支付";
      this.playLoading = true;
      let url = window.configData.VUE_APP_BASEURL + "/cus/pay/package";
      if (this.renewData.playWay == "ALIPAY") {
        this.logo = this.logo_zfb;
        data.returnUrl = "https://dendralink.com/#/userCenter";
        url = window.configData.VUE_APP_BASEURL + "/cus/pay/package/alipay";
        this.wayTitle = "支付宝支付";
      }
      if (data.billingCycle == 0) {
        axios
          .post(window.configData.VUE_APP_BASEURL + "/cus/pay/package/zero", data, {
            headers: {
              Authorization: "Bearer " + getToken()
            }
          })
          .then(response => {
            let res = response.data;
            if (res.code == 200 && res.data) {
              this.playLoading = false;
              this.showRenew = false;
              this.$message.success("支付成功");
              this.$store.dispatch("user/getUserVip");
              this.$store.dispatch("user/getUserInfo");
            }
          })
          .catch(error => {
            this.playLoading = false;
            // 请求失败处理
            this.$message.warning(error.response.data.message)
          });;
      } else {
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + getToken()
            }
          })
          .then(response => {
            let res = response.data;
            console.log("支付", response, res);
            this.playLoading = false;
            if (this.renewData.playWay == "ALIPAY") {
              if (res.code && res.code == 10001) {
                this.$message.warning(
                  res.message || "系统错误，请联系管理员!!"
                );
                return;
              }
              this.applyForm = res;
              this.$nextTick(() => {
                document.querySelector(".ali_form form");
                // .setAttribute("target", "_blank");
                document.querySelectorAll(".ali_form form input")[1].click();
              });
              return;
            }
            if (res.code == 200) {
              if (res.data && res.data.orderUid) {
                if (res.data.qrCodeUrl) {
                  this.imgUrl = res.data.qrCodeUrl;
                  this.showPlay = true;
                }
                this.orderUid = res.data.orderUid;
                this.getPlayStatus();
              }
            } else this.$message.warning("系统错误，请联系管理员！！！");
          })
          .catch(error => {
            this.playLoading = false;
            // 请求失败处理
            this.$message.warning(error.response.data.message)

          });
      }
    },
    getPlayStatus () {
      if (!this.timer) {
        this.timer = setInterval(() => {
          axios
            .get(
              window.configData.VUE_APP_BASEURL + "/cus/pay/check?orderUid=" +
              this.orderUid,
              {
                headers: {
                  Authorization: "Bearer " + getToken()
                }
              }
            )
            .then(response => {
              let res = response.data;
              this.loading = false;
              if ((res.code = 200 && res.data.status)) {
                if (res.data.status.indexOf("PAID") > -1) {
                  this.$message.success("支付成功");
                  this.clerTimes();
                } else if (res.data.status.indexOf("CLOSED") > -1) {
                  this.$message.success("支付已关闭");
                  this.clerTimes();
                } else if (res.data.status.indexOf("REFUNDED") > -1) {
                  this.$message.success("已退款");
                  this.clerTimes();
                }
                if (
                  res.data.status.indexOf("PAID") > -1 ||
                  res.data.status.indexOf("CLOSED") > -1 ||
                  res.data.status.indexOf("REFUNDED") > -1 ||
                  res.data.status.indexOf("UNKNOWN") > -1
                ) {
                  this.loading = false;
                  this.statusTxt = "";
                  clearInterval(this.timer);
                  this.timer = null;
                  this.clerTimes();
                  this.showPlay = false;
                  this.showRenew = false;
                } else if (
                  res.data.status.indexOf("PENDING") > -1 ||
                  res.data.status.indexOf("PAYING") > -1 ||
                  res.data.status.indexOf("REFUNDING") > -1
                ) {
                  this.loading = true;
                  this.statusTxt =
                    res.data.status.indexOf("PENDING") > -1
                      ? "支付初始化中"
                      : res.data.status.indexOf("PAYING") > -1
                        ? "正在支付中"
                        : res.data.status.indexOf("REFUNDING") > -1
                          ? "退款中..."
                          : "";
                  this.clerTimes();
                }
              }
              //   } else
              //     this.$message.warning(
              //       (res.data && res.data.status) ||
              //         "系统错误，请联系管理员！！！"
              //     );
              //   clearInterval(this.timer);
              //   this.timer = null;
              //   this.showPlay = false;
              //   this.showRenew = false;
            });
        }, 5000);
      }
    },
    changeWay (way) {
      if (way == this.renewData.playWay) return;
      this.renewData.playWay = way;
    },
    clerTimes () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.$store.dispatch("user/getUserVip");
      this.$store.dispatch("user/getUserInfo");
    },
    getOrderId (orderid) {
      if (!orderid) return;
      this.orderUid = orderid;
      this.getPlayStatus();
    }
  },
  watch: {},
  destroyed () {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>
<style lang="stylus" scoped>
.member-container
  width 100%
  height 100%
  overflow hidden

  .member-wrapper
    padding 20px 0
    width 100%
    height 100%
    overflow-y auto
    box-sizing border-box

  .member-box
    padding 32px 117px
    background #fff
    border-radius 8px

  .member-title
    font-size 24px
    font-weight 600
    color #222
    text-align center

  .member-desc
    margin-top 8px
    margin-bottom 24px
    font-size 14px
    color #999
    font-weight 400
    text-align center

  .member-type
    position relative
    width 100%

    .member-tyb
      position relative
      margin-left 24px
      cursor pointer
      font-size 14px
      font-weight 400
      font-style normal
      color #3478F7

      &:before
        position absolute
        bottom -5px
        content ''
        display block
        width 100%
        height 1px
        background #3478F7

  .member-type-box
    overflow hidden
    box-sizing border-box
    border-radius 5px

  .member-type-item
    width 86px
    height 32px
    font-size 14px
    font-weight 400
    color #222
    cursor pointer

  .member-type-active
    background-color #3478f7
    color #fff

// 选择
.member-play
  justify-content space-between
  margin-top 32px

  .member-play-item
    width 345px
    padding 8px 12px
    border-radius 5px
    box-shadow 0px 8px 20px 0px rgba(9, 43, 80, .2)

  .paly-svip
    position relative
    border 1px solid #ffba48
    background #fff3e1

    .svip-img
      position absolute
      top 0
      left 0
      width 78px
      height 78px

    .paly-btn-box
      >>>.el-button
        background #ffb337
        border-color #ffb337
        color #222

  .play-item-top
    height 150px

  .play-title
    padding 16px 0
    font-size 18px
    font-weight 600
    color #222

  .play-desc
    font-size 14px
    color #999
    font-weight 400

  .icon-title
    width 25px
    margin-right 8px

  .play-money
    align-items flex-end
    font-size 18px
    color #FFB337
    font-weight 700

    .money
      font-size 24px
      margin-left 4px

  .paly-btn-box
    width 100%
    margin 16px 0
    font-size 14px
    color #222
    font-weight 400

    >>>.el-button
      width 140px
      height 36px
      padding 0
      background #3478f7
      border-color #3478f7

  .play-list
    width 100%
    margin-bottom 24px
    box-sizing border-box
    border 1px solid #f3f3f3
    background #ffffff

    .play-list-item
      align-items center
      padding 0px 16px

      &:first-child
        background #f6f6f6

      .play-name
        width 75%
        min-height 40px
        font-size 12px
        font-weight 400
        color #222
        flex-direction column
        border-right 1px solid #f3f3f3
        align-items flex-start

      .play-icon-box
        width 25%
        min-height 40px
        text-align center
        white-space pre-wrap

        img
          width 23px
          height 23px

.form-wrapper
  .member-play
    margin-top 0

  .play-item-top
    height 100px

  .member-play-item
    width 100%

.type-desc
  display inline-block
  height 30px
  line-height 30px
  background rgba(255, 179, 55, .1)
  border-radius 4px
  font-size 12px
  color #FFB337
  padding 0 6px

  img
    width 14px
    height 14px
    margin-right 4px
    vertical-align middle

.renew-time
  margin-left 16px
  font-size 12px
  color #999
  font-weight 400

.zf-item
  padding 0 12px
  height 32px
  margin-right 12px
  align-items center
  background #FFFFFF
  border-radius 2px
  font-size 14px
  color #222
  font-weight 400
  cursor pointer
  border 1px dashed #EEEEEE

  img
    width 20px
    height 20px
    margin-right 8px

.zf-item-active
  background #fff
  color #FFB337
  border-color #FFB337
</style>
